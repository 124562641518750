/** @format */

import httpFetch from 'src/util/httpFetch';
//余额相关
const userAmountApi = {
    name: 'userAmount',
    apis: {
        /**
         * 余额查询
         * @returns {*}
         */
        adminUserAmountGet(params){
            return httpFetch('post', `/api/userAmount/adminUserAmountGet`,params);
        },
    }
};
export default userAmountApi;
