/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//aspiration相关
const aspiration = {
    name: 'aspiration',
    apis: {
        /**
         * 查询某用户填报的志愿
         * @param params
         * @returns {*}
         */
        adminUserAspirationList(params) {
            return httpFetch('post', '/api/aspiration/adminUserAspirationList', params);
        },
        /**
         * 查询用户专业报考情况
         * @param params
         * @returns {*}
         */
        userAspirationMajorList(params) {
            return httpFetch('post', '/api/aspiration/userAspirationMajorList', params);
        },
        /**
         * 查询用户专业报考情况
         * @param params
         * @returns {*}
         */
        aspirationSchoolEdit(params) {
            return httpFetch('post', '/api/aspiration/aspirationSchoolEdit', params);
        },
        /**
         * 把学校加入至志愿表
         * @param params
         * @returns {*}
         */
        aspirationCreate(params) {
            return httpFetch('post', '/api/aspiration/aspirationCreate', params);
        },
        /**
         * 把学校的专业加入至志愿表的学校列表中
         * @param params
         * @returns {*}
         */
        aspirationMarjoCreate(params) {
            return httpFetch('post', '/api/aspiration/aspirationMarjoCreate', params);
        },
        /**
         * 学校志愿的移动/上移/下移
         * @param params
         * @returns {*}
         */
        aspirationSchoolMove(params) {
            return httpFetch('post', '/api/aspiration/aspirationSchoolMove', params);
        },
        /**
         * 专业志愿的移动/上移/下移
         * @param params
         * @returns {*}
         */
        aspirationMajorMove(params) {
            return httpFetch('post', '/api/aspiration/aspirationMajorMove', params);
        },
        /**
         * 学校志愿删除
         * @param params
         * @returns {*}
         */
        aspirationSchoolDelete(params) {
            return httpFetch('post', '/api/aspiration/aspirationSchoolDelete', params);
        },
        /**
         * 专业志愿删除
         * @param params
         * @returns {*}
         */
        aspirationMajorDelete(params) {
            return httpFetch('post', '/api/aspiration/aspirationMajorDelete', params);
        },

    }
};
export default aspiration;
