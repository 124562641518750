/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//交通图标
const ruleIcon = {
    name: 'ruleIcon',
    apis: {
        /**
         * 交通图标列表
         * @param params
         * @returns {*}
         */
        ruleIconList(params){
            return httpFetch('post', `/api/ruleIcon/ruleIconList`,params);
        },
		/**
		 * 交通图标详情
		 * @param params
		 * @returns {*}
		 */
		ruleIconShow(params){
			return httpFetch('post', `/api/ruleIcon/ruleIconShow`,params);
		},
		/**
		 * 交通图标编辑
		 * @param params
		 * @returns {*}
		 */
		ruleIconEdit(params){
			return httpFetch('post', `/api/ruleIcon/ruleIconCreate`,params);
		},
        /**
         * 初始化
         * @param params
         * @returns {*}
         */
        ruleIconInit(params){
            return httpFetch('post', `/api/ruleIcon/ruleIconInit`,params);
        },

    }
};
export default ruleIcon;
