/*
 * action 类型
 */
export const location  = 'location';


/*
 * 存储
 */
export function store(value) {

    return { type: location, value: {
            data: value,
            loading:false
        } }
}