/** @format */

import httpFetch from 'src/util/httpFetch';
//资源相关
const frontResource = {
    name: 'frontResource',
    apis: {
        /**
         * 资源list
         * frontResource 资源号
         */
        frontResourceList(params) {
            return httpFetch('post', `/api/frontResource/frontResourceList`, params);
        },
        /**
         * 资源详情
         * frontResource 资源号
         */
        frontResourceShow(params) {
            return httpFetch('post', `/api/frontResource/frontResourceShow`, params);
        },
        /**
         * 资源创建
         * frontResource 资源号
         */
        frontResourceCreate(params) {
            return httpFetch('post', `/api/frontResource/frontResourceCreate`, params);
        },

    }
};
export default frontResource;
