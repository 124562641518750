/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//通知相关
const inform = {
    name: 'inform',
    apis: {
      /**
       * 删除通知
       */
      gzhinform(params) {
        return httpFetch('post', `/api/inform/gzhinform`,params);
      },


        /**
         * 获取所有内部通知的list
         * @param params
         * @returns {*}
         */
        informList(params) {
            return httpFetch('post', '/api/inform/list', params);
        },
        /**
         * 管理员获取所有通知的list
         * @param params
         * @returns {*}
         */
        adminInformList(params) {
            return httpFetch('post', '/api/inform/adminInformList', params);
        },
        /**
         * 获取当前用户的通知
         * @param params
         * @returns {*}
         */
        insideInformList(params) {
            return httpFetch('post', '/api/inform/insideInformList', params);
        },
        /**
         * 通知已读
         * @param params
         * @returns {*}
         */
        insideInformRead(params) {
            return httpFetch('post', '/api/inform/insideInformRead', params);
        },

        /**
         * 通知详情
         */
        informShow(params) {
            return httpFetch('post', `/api/inform/informShow`,params);
        },
        /**
         * 通知发布
         * @param params
         * @returns {*}
         */
        informPost(params) {
            return httpFetch('post', '/api/inform/insideInformEdit', params);
        },
        /**
         * 查询通知里的卡
         * @param params
         * @returns {*}
         */
        cardsInInformList(params) {
            return httpFetch('post', '/api/inform/cardsInInformList', params);
        },
        /**
         * 通知初始化
         * @param params
         * @returns {*}
         */
        informInit(params) {
            return httpFetch('post', '/api/inform/informInit', params);
        },
        /**
         * 抽奖（测试）
         * @param params
         * @returns {*}
         */
        pickUpTimes(params) {
            return httpFetch('post', '/api/inform/pickUpTimes', params);
        },
    }
};
export default inform;
