/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//枚举
const subject = {
    name: 'QrCode',
    apis: {
        /**
         *  获取二维码
         * @returns {*}
         */
        qrCodeCreate(){
            return httpFetch('post', `/api/qrCode/qrCodeCreate`);
        },
        qrCodeOrderCreate(params){
            return httpFetch('post', `/api/qrCode/qrCodeOrderCreate`,params);
        },

        /**
         *  二维码
         * @returns {*}
         */
        qrCodeState(params){
            return httpFetch('post', `/api/qrCode/qrCodeState`,params);
        },

    }
};
export default subject;
