
/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//评论相关
const commentSource = {
    name: 'commentSource',
    apis: {
        /**
         * 从爬的数据转到真实的表中。
         * @param params
         * @returns {*}
         */
        commentSourceList(params) {
            return httpFetch('post', '/api/commentSource/commentSourceList', params);
        },
        bindSubjectData(params) {
            return httpFetch('post', '/api/commentSource/bindSubjectData', params);
        },
        /**
         * 自动绑定
         * @param params
         * @returns {Promise<*>}
         */
        autoBind(params){
            return httpFetch('post', `/api/commentSource/autoBind`,params);
        },
        toShunxu(params){
            return httpFetch('post', `/api/commentSource/toShunxu`,params);
        },
        bindData(params) {
            return httpFetch('post', '/api/commentSource/bindData', params);
        },
        commentSourceMark(params) {
            return httpFetch('post', '/api/commentSource/commentSourceMark', params);
        },
    }
};
export default commentSource;
