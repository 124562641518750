/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//交通图标类型
const ruleIconType = {
    name: 'ruleIconType',
    apis: {
        /**
         * 交通图标类型列表
         * @param params
         * @returns {*}
         */
        ruleIconTypeList(params){
            return httpFetch('post', `/api/ruleIconType/ruleIconTypeList`,params);
        },
		/**
		 * 交通图标类型列表
		 * @param params
		 * @returns {*}
		 */
		ruleIconTypeShow(params){
			return httpFetch('post', `/api/ruleIconType/ruleIconTypeShow`,params);
		},
		/**
		 * 交通图标类型列表
		 * @param params
		 * @returns {*}
		 */
		ruleIconTypeEdit(params){
			return httpFetch('post', `/api/ruleIconType/ruleIconTypeCreate`,params);
		},
        /**
         * 交通图标类型分享图坐标调整
         * @param params
         * @returns {*}
         */
        ruleIconTypeAxisEdit(params){
            return httpFetch('post', `/api/ruleIconType/ruleIconTypeAxisEdit`,params);
        },

    }
};
export default ruleIconType;
