/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//userResource查询相关
const userResource = {
    name: 'userResource',
    apis: {
        userItemShareImage(params) {
            return httpFetch('post', '/api/userResource/userItemShareImage',params);
        },
        coachShareImage(params) {
            return httpFetch('post', '/api/userResource/coachShareImage',params);
        },
        userResourceRemove(params) {
            return httpFetch('post', '/api/userResource/userResourceRemove',params);
        },
        //删除某用户全部静态资源
        userResourceDelete(params) {
            return httpFetch('post', '/api/userResource/userResourceDelete',params);
        },
        //管理员帮教练生成二维码
        managerCreateShareImage(params) {
            return httpFetch('post', '/api/userResource/managerCreateShareImage',params);
        },

    }
};
export default userResource;
