/** @format */

import { combineReducers } from 'redux';
import history from './history';
import User from './User';
import Enume from './Enume';
import Location from './Location';

//合并,从props可以获得数据
export default combineReducers({
    history, //路由
    User, //用户
    Enume, // 枚举相关,
    Location
});
