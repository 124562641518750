import * as action from './action'
const {Enume} = action;
const initState = {
    loading:true,
    locationData:[],
};
function history(state = initState,action){
    switch (action.type){
        case Enume:
            return Object.assign({}, state, action.value);
        default:
            return state;
    }
}
export default history;
