/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//推广机构相关
const mark = {
    name: 'mark',
    apis: {
        /**
         * 推广机构列表
         * @param params
         * @returns {*}
         */
        markList(params){
            return httpFetch('post', `/api/mark/markList`,params);
        },
		/**
		 * 推广机构列表
		 * @param params
		 * @returns {*}
		 */
		markShow(params){
			return httpFetch('post', `/api/mark/markShow`,params);
		},
		/**
		 * 推广机构列表
		 * @param params
		 * @returns {*}
		 */
		markEdit(params){
			return httpFetch('post', `/api/mark/markCreate`,params);
		},
    }
};
export default mark;
