/** @format */

import httpFetch from 'src/util/httpFetch';
//版本相关
const version = {
    name: 'version',
    apis: {
        /**
         * 版本list
         * version 版本号
         */
        versionList(params) {
            return httpFetch('post', `/api/version/list`, params);
        },
        /**
         * 版本详情
         * version 版本号
         */
        versionShow(params) {
            return httpFetch('post', `/api/version/versionShow`, params);
        },
        /**
         * 版本创建
         * version 版本号
         */
        versionCreate(params) {
            return httpFetch('post', `/api/version/versionCreate`, params);
        },
        /**
         * 对比版本号
         * version 版本号
         */
        compareVersion(params) {
            return httpFetch('post', `/api/version/compareVersion`, params);
        },

    }
};
export default version;
