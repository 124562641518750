/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//枚举
const subject = {
    name: 'Enume',
    apis: {
        /**
         * 所有的枚举
         * @returns {*}
         */
        EnumeMap(){
            return httpFetch('get', `/api/enume/enumeMap`);
        },
        /**
         *  省数据
         * @returns {*}
         */
        provinceList(){
            return httpFetch('get', `/api/enume/provinceList`);
        },
        /**
         * 获取全部地域数据
         * @returns {*}
         */
        locationDataGet(){
            return httpFetch('post', `/api/enume/locationDataGet`);
        },
    }
};
export default subject;
