/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//视频相关
const order = {
    name: 'order',
    apis: {
        /**
         * 订单详情
         * @param params
         * @returns {*}
         */
        adminOrderShow(params) {
            return httpFetch('post', `/api/order/adminOrderShow`, params);
        },
        /**
         * 订单list
         * @param params
         * @returns {*}
         */
        orderList(params) {
            return httpFetch('post', `/api/order/orderList`, params);
        },
        /**
         * 订单创建
         * @returns {*}
         */
        orderCreate(params) {
            return httpFetch('post', `/api/order/orderCreate`, params);
        },
        /**
         * 补偿因分享错误而没有返点情况
         * @returns {*}
         */
        moneyGet(params) {
            return httpFetch('post', `/api/order/moneyGet`, params);
        },
        /**
         * 重发支付通知
         * @param params
         * @returns {*}
         */
        moneyInform(params) {
            return httpFetch('post', `/api/order/moneyInform`, params);
        }
    }
};
export default order;
