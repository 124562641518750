/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//count查询相关
const count = {
    name: 'count',
    apis: {
        //流水-利润
        orderCountInit(param) {
            return httpFetch('post', '/api/count/orderCountInit',param);
        },
        //流水-利润
        moneyCount(param) {
            return httpFetch('post', '/api/count/moneyCount',param);
        },
        //流水-利润
        userRegisterCount(param) {
            return httpFetch('get', '/api/count/userRegisterCount',param);
        },
    }
};
export default count;
