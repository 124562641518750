/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//视频相关
const user = {
    name: 'user',
    apis: {
        /**
         * 用户自动登录
         * @returns {*}
         */
        autoLogin(params){
            return httpFetch('post', '/api/user/autoLogin',params);
        },
        /**
         * 登录
         * @param params
         * @account 账号。
         * @psw 密码
         */
        login(params) {
            return httpFetch('post', '/api/user/adminLogin', params);
        },
        /**
         * admin查询用户信息
         * @param params
         * @returns {*}
         */
        adminUserList(params) {
            return httpFetch('post', '/api/user/adminUserList', params);
        },
        /**
         *  用户登录状态检查
         * @returns {*}
         */
        userLoginState() {
            return httpFetch('get', '/api/user/loginCheck');
        },
        /**
         * 模糊搜索
         * @param params
         * @returns {*}
         */
        userSearch(params) {
            return httpFetch('post', '/api/user/userSearch', params);
        },
        /**
         * 机构关联用户
         * @param params
         * @returns {Promise<T>}
         */
        connectMarker(params) {
            return httpFetch('post', '/api/user/connectMarker', params);
        },
        /**
         * 用户布尔值设置
         * @param params
         * @returns {*}
         */
        userBooleanSet(params) {
            return httpFetch('post', '/api/user/userBooleanSet', params);
        },
        /**
         * 管理员设置用户为学员或教练
         * @param params
         * @returns {*}
         */
        setUserType(params) {
            return httpFetch('post', '/api/user/setUserType', params);
        },
        /**
         * 用户封禁部分功能
         * @param params
         * @returns {*}
         */
        userFuncBan(params) {
            return httpFetch('post', '/api/user/userFuncBan', params);
        },
        /**
         * 用户封禁部分功能
         * @param params
         * @returns {*}
         */
        verifyAppleIdToken(params) {
            return httpFetch('post', '/api/user/verifyAppleIdToken', params);
        },
        /**
         * 用户封禁部分功能
         * @param params
         * @returns {*}
         */
        managerHelpPhoneNumRegister(params) {
            return httpFetch('post', '/api/user/managerHelpPhoneNumRegister', params);
        },

    }
};
export default user;
