import * as action from './action'
const {location} = action;
const initState = {
    loading:true,
    data:[]
};
function history(state = initState,action){
    switch (action.type){
        case location:
            return Object.assign({}, state, action.value);
        default:
            return state;
    }
}
export default history;
