/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//resource查询相关
const resource = {
    name: 'resource',
    apis: {
        //资源图获取
        resourceGet(params) {
            return httpFetch('post', '/api/resource/resourceGet',params);
        },
        //单个资源获取
        resourceShow(params) {
            return httpFetch('post', '/api/resource/resourceShow',params);
        },
        //图编辑
        resourceUpload(params) {
            return httpFetch('post', '/api/resource/resourceUpload',params);
        },
        //条件获取资源
        resourceByBelongId(params) {
            return httpFetch('post', '/api/resource/resourceByBelongId',params);
        },
        //坐标编辑
        resourceAxisEdit(params) {
            return httpFetch('post', '/api/resource/resourceAxisEdit',params);
        },
    }
};
export default resource;
