/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//评论相关
const comment = {
    name: 'comment',
    apis: {
        /**
         * 评论列表获取
         * @param params
         * @returns {*}
         */
        commentList(params) {
            return httpFetch('post', '/api/comment/adminCommentList', params);
        },
        /**
         * 评论详情
         * @param params
         * @returns {*}
         */
        commentShow(params) {
            return httpFetch('post', '/api/comment/commentShow', params);
        },
        /**
         * 评论发布
         * @param params
         * @returns {*}
         */
        commentPost(params) {
            return httpFetch('post', '/api/comment/commentCreate', params);
        },
        /**
         * 公共评论单独发布
         * @param params
         * @returns {*}
         */
        createPublicComment(params) {
            return httpFetch('post', '/api/comment/createPublicComment', params);
        },
        /**
         * 获取可评论的用户
         * @returns {*}
         */
        commentUserList(params) {
            return httpFetch('post', `/api/comment/commentUser`,params);
        },
        /**
         * 通过评论
         */
        commentPass(params) {
            return httpFetch('post', `/api/comment/commentPass`, params);
        },

        /**
         * 删除评论
         */
        commentDelete(params) {
            return httpFetch('post', `/api/comment/commentDelete`,params);
        }
    }
};
export default comment;
