/** @format */

import httpFetch from 'src/util/httpFetch';
//物流相关
const logistics = {
    name: 'logistics',
    apis: {
        /**
         * 获取物流列表
         * @param params
         * @returns {*}
         */
        adminLogisticsList(params) {
            return httpFetch('post', '/api/logistics/adminLogisticsList', params);
        },

        adminLogisticsShow(params){
            return httpFetch('post', '/api/logistics/adminLogisticsShow', params);
        },
        /**
         * 创建包裹
         */
        logisticsCreate(params) {
            return httpFetch('post', `/api/logistics/logisticsCreate`,params);
        },
        /**
         * 包裹回滚-删除包裹并还原物品状态
         */
        logisticsRollBack(params) {
            return httpFetch('post', `/api/logistics/logisticsRollBack`,params);
        },
        /**
         * 更新物流订单信息
         */
        logisticsOrderUpdate(params) {
            return httpFetch('post', `/api/logistics/logisticsOrderUpdate`,params);
        },
        /**
         * 获取微信可用的物流列表
         */
        logisticsCompanyWxList(params) {
            return httpFetch('post', `/api/logistics/logisticsCompanyWxList`,params);
        },
        /**
         * 自定义物流下单
         */
        createSelfLogisticsOrder(params) {
            return httpFetch('post', `/api/logistics/createSelfLogisticsOrder`,params);
        },
        /**
         * 微信物流下单
         */
        createWxLogisticsOrder(params) {
            return httpFetch('post', `/api/logistics/createWxLogisticsOrder`,params);
        },
        /**
         * 物流轨迹
         */
        wxLogisticsGetPath(params) {
            return httpFetch('post', `/api/logistics/wxLogisticsGetPath`,params);
        },
        /**
         * 确认收货
         */
        adminLogisticsSign(params) {
            return httpFetch('post', `/api/logistics/adminLogisticsSign`,params);
        },
        /**
         * 测试微信物流快递变更
         */
        wxLogisticsTest(params) {
            return httpFetch('post', `/api/logistics/wxLogisticsTest`,params);
        },
    }
};
export default logistics;
