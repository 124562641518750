/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//票相关
const ticket = {
    name: 'ticket',
    apis: {
        /**
         * 获取所有票的list
         * @param params
         * @returns {*}
         */
        ticketList(params) {
            return httpFetch('post', '/api/ticket/list', params);
        },
        /**
         * 票详情
         */
        ticketShow(params) {
            return httpFetch('post', `/api/ticket/ticketShow`,params);
        },
        /**
         * 票发布
         * @param params
         * @returns {*}
         */
        ticketPost(params) {
            return httpFetch('post', '/api/ticket/ticketCreate', params);
        },
    }
};
export default ticket;
