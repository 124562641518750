/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//admin查询相关
const admin = {
    name: 'admin',
    apis: {
        //计算收益
        cacheRemoveAll(params){
            return httpFetch('post', '/api/admin/cacheRemoveAll',params);
        },
        //修改用户权益
        changeItems(params){
            return httpFetch('post', ' api/admin/changeItems',params);
        },
        salesList(params){
            return httpFetch('post', '/api/admin/salesList',params);
        },
        aliPhoneMsgPost(params){
            return httpFetch('post', '/api/message/aliPhoneMsgPost',params);
        },
    }
};
export default admin;
