/** @format */

import httpFetch from 'src/util/httpFetch';
//题库相关
const pool = {
    name: 'pool',
    apis: {
        /**
         * 宝典题目入库
         * @returns {*}
         */
        // baodiandataJsonPost(params){
        //     return httpFetch('post', `/api/subject/jikaoBaoDianIn`,params);
        // },
        /**
         * 一点通题目入库
         * @returns {*}
         */
        // yiDianTongIn(params){
        //     return httpFetch('post', `/api/subject/yiDianTongIn`,params);
        // },
        /**
         * 题库初始化处理
         * @param params
         * @returns {*}
         */
        initPool(params){
            return httpFetch('post', `/api/pool/initPool`,params);
        },
        /**
         * 调整题目入库后的位置
         * @param params
         * @returns {*}
         */
        subjectPoolEdit(params){
            return httpFetch('post', `/api/pool/subjectPoolEdit`,params);
        },
        /**
         * 题库入题（单题单库）
         * @param params
         * @returns {*}
         */
        subjectToPool(params){
            return httpFetch('post', `/api/pool/subjectToPool`,params);
        },
        /**
         * 题库入题（多题多库）
         * @param params
         * @returns {*}
         */
        subjectsToPools(params){
            return httpFetch('post', `/api/pool/subjectsToPools`,params);
        },
        /**
         * 题库list
         * @param params
         * @returns {*}
         */
        poolList(params){
            return httpFetch('post', `/api/pool/poolList`,params);
        },
        /**
         * 首页题库
         * @param params
         * @returns {*}
         */
        homePoolList(params){
            return httpFetch('post', `/api/pool/homePoolList`,params);
        },
        poolDetail(params){
            return httpFetch('post', `/api/pool/poolDetail`,params);
        },
        createPool(params){
            return httpFetch('post', `/api/pool/createPool`,params);
        },
        poolDelete(params){
            return httpFetch('post', `/api/pool/poolDelete`,params);
        },
        poolCombine(params){
            return httpFetch('post', `/api/pool/poolCombine`,params);
        },
        poolSearch(params){
            return httpFetch('post', `/api/pool/poolSearch`,params);
        },
        /**
         * 拼装源题库查看
         * @param params
         * @returns {*}
         */
        combinePoolsGet(params){
            return httpFetch('post', `/api/pool/combinePoolsGet`,params);
        },
    }
};
export default pool;
